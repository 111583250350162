<template>
  <div>
    <vs-popup class="sm:popup-w-60" title="Filter" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Periode Awal</label>
            <vx-input-group>
              <flat-pickr class="w-full" v-model="data.periode_awal"></flat-pickr>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-calendar"/>
                </div>
              </template>
            </vx-input-group>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Periode Akhir</label>
            <vx-input-group>
              <flat-pickr class="w-full" v-model="data.periode_akhir"></flat-pickr>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-calendar"/>
                </div>
              </template>
            </vx-input-group>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Proyek</label>
            <v-select :options="proyeks" @input="onChangeProyek" label="nama" :reduce="item => item.id" :clearable="true" v-model="data.id_proyek"/>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Properti Kategori</label>
            <v-select :options="propertiKategoris" @input="onChangeKategori" label="nama" :reduce="item => item.id" :clearable="true" v-model="data.id_properti_kategori"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Properti Tipe</label>
            <v-select :options="propertiTipes" @input="onChangeTipe" label="nama" :reduce="item => item.id" :clearable="true" v-model="data.id_properti_tipe"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Cluster</label>
            <v-select :options="clusters" label="nama" :reduce="item => item.id" :clearable="true" v-model="data.id_cluster"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Properti Unit</label>
            <v-select :options="propertiUnits" label="no_unit" :reduce="item => item.id" :clearable="true" v-model="data.id_properti_unit"/>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Staf Marketing</label>
            <v-select :options="stafs" label="nama" :clearable="true" v-model="data.id_staf"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Cara Bayar</label>
            <v-select :options="caraBayars" label="nama" :reduce="item => item.id" :clearable="true" v-model="data.id_cara_bayar"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Status Kontrak</label>
            <v-select :options="statusKontrak" :clearable="true" v-model="data.status_kontrak"/>
          </div>
        </div>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" color="danger" type="border" @click="clearFilter" :disabled="isEmptyFilter">Clear Filter</vs-button>
            <vs-button color="primary" @click="filter" :disabled="isEmptyFilter" @filter="onFilter">Filter</vs-button>
          </div>
        </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import ProyekRepository from '@/repositories/master/proyek-repository'
import PropertiKategoriRepository from '@/repositories/master/properti-kategori-repository'
import PropertiTipeRepository from '@/repositories/master/properti-tipe-repository'
import ClusterRepository from '@/repositories/master/cluster-repository'
import PropertiUnitRepository from '@/repositories/master/properti-unit-repository'
import StafRepository from '@/repositories/master/staf-repository'
import CaraBayarRepository from '@/repositories/master/cara-bayar-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'

export default {
  name: 'KontrakFilter',
  props: ['isActive'],
  components: {
    ValidationErrors,
    flatPickr,
    'v-select': vSelect
  },
  watch: {
    isActive (value) {
      if (value === true && !this.isDataInited) {
        this.initData()
        this.isDataInited = true
      }
    }
  },
  computed: {
    isEmptyFilter () {
      return _.isEmpty(this.data)
    }
  },
  data () {
    return {
      isDataInited: false,
      errors: null,
      data: {},
      statusKontrak: ['OPEN', 'BATAL', 'CLOSE', 'OVER KONTRAK'],
      proyeks: [],
      propertiKategoris: [],
      propertiTipes: [],
      clusters: [],
      propertiUnits: [],
      stafs: [],
      caraBayars: []
    }
  },
  methods: {
    onFilter () {
    },

    initData () {
      this.getProyek()
      this.getAllCluster()
      this.getAllStaf()
      this.getAllCaraBayar()
    },

    onChangeProyek () {
      this.propertiKategoris = []
      this.propertiTipes = []
      this.data.id_properti_kategori = null
      this.data.id_properti_tipe = null
      this.getPropertiUnit()
      this.getPropertiKategori()
    },

    onChangeKategori () {
      this.propertiTipes = []
      this.data.id_properti_tipe = null
      this.getPropertiUnit()
      this.getPropertiTipe()
    },

    onChangeTipe () {
      this.getPropertiUnit()
    },

    getProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getPropertiKategori () {
      const idProyek = this.data.id_proyek
      PropertiKategoriRepository.getPropertiKategoriByProyek(idProyek)
        .then(response => {
          this.propertiKategoris = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getPropertiTipe () {
      const idKategori = this.data.id_properti_kategori
      PropertiTipeRepository.getAllPropertiTipeByKategori(idKategori)
        .then(response => {
          this.propertiTipes = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getPropertiUnit () {
      const params = {}
      if (!this.isEmptyFilter) {
        params.filter = JSON.stringify(this.data)
      }
      PropertiUnitRepository.getAll(params)
        .then(response => {
          this.propertiUnits = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getAllCluster () {
      ClusterRepository.getAllCluster()
        .then(response => {
          this.clusters = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getAllStaf () {
      StafRepository.getAll()
        .then(response => {
          this.stafs = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getAllCaraBayar () {
      CaraBayarRepository.getAll()
        .then(response => {
          this.caraBayars = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    filter () {
      this.$emit('filter', this.data)
      this.emitModalIsActive(false)
    },

    clearFilter () {
      this.data = {}
      this.$emit('filter', {})
      this.emitModalIsActive(false)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
